import styles from "../Steps.module.scss";
import CheckIcon from '@mui/icons-material/Check';

const Nav = (props) => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    const isFinished = props.currentStep > i;
    const stepDetail = props.stepDetails[i - 1];
    dots.push(
      <div key={`step-${i}`} className={`${styles["wrapper__header__nav"]} ${isActive ? `${styles["wrapper__header--active_nav"]}` : ""} ${isFinished ? `${styles["wrapper__header--finished_nav"]}` : ""}`}>
        <span className={styles.wrapper__header__container}>
          {
            isFinished ?
              <span >
                <span className={styles["wrapper__header--finished_nav__icon"]}><CheckIcon /></span>
              </span> :
              <span className={`${styles["wrapper__header__nav__number"]} ${isActive ? `${styles["wrapper__header--active_nav__number"]}` : ""}`}>
                <span>{i}</span>
              </span>
          }
          <span className={styles.navtext}>
            <span>{stepDetail.name}</span>{" "}
          </span>
        </span>
      </div>
    );
  }

  return <div className={styles.wrapper__header}>{dots}</div>;
};
export default Nav;
