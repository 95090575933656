import styles from "./TextField.module.scss";
import { InputField, InputWrapper } from './styles'
import { useRef } from "react";

const TextField = ({
  onChange,
  onBlur,
  name,
  value,
  error,
  touched,
  placeholderComponent,
  type,
  className,
  children,
  accept,
  label,
  rows,
  multiline,
  autoComplete,
  InputProps,
  focused,
  hidden,
  style,
  id,
  maxLenght,
  noSpecialCharacters = false,
  onlyNumbers = false,
  userName = false,
}) => {
  const prevInputState = useRef('')
  const limitNumber = (e) => {
    const value = e.target.value
    if (parseInt(e.target.value) < InputProps.min) {
      e.target.value = InputProps.min.toString();
    } else if (parseInt(e.target.value) === InputProps.max + 1) {
      e.target.value = InputProps.max.toString();
    } else if (parseInt(e.target.value) > InputProps.max) {
      e.target.value = e.target.value.substring(0, value.length - 1)
    }
  }
  const deleteCharactersDifferentToNumber = (e) => {
    const format = /^[0-9]+([0-9]+)*$/;
    if (!format.test(e.target.value)) {
      e.target.value = prevInputState.current
    }
  }
  const deleteEmojisOnPaste = (e) => {
    const format = /^[a-zA-ZÀ-ÿñÑ\s]+( [a-zA-ZÀ-ÿñÑ\s]+)*$/;
    if(!format.test(e.clipboardData.getData('text'))){
      e.preventDefault()
    }
  }
  const handleChange = (e) => {
    if (type === 'number') {
      limitNumber(e);
    }
    if (onlyNumbers) {
      if (e.target.value !== "") {
        deleteCharactersDifferentToNumber(e);
      }
      prevInputState.current = e.target.value;
    }
    onChange(e); 
  }
  const handleOnPaste = (e) => {
    deleteEmojisOnPaste(e)
  }
  const handleOnInput = (e) => {
    if(noSpecialCharacters && e.target.value !== "") {
      let format = /^[a-zA-ZÀ-ÿñÑ\s]+( [a-zA-ZÀ-ÿñÑ\s]+)*$/;
      if (!format.test(e.target.value)) {
        return e.target.value = ""
      }
      prevInputState.current = e.target.value
    }
    if(userName) {
      if(e.target.value !== "") {
        let formatUsername = /^[a-zA-Z0-9._-]+$/
        if(!formatUsername.test(e.target.value)){
          e.target.value = prevInputState.current 
        }
        prevInputState.current = e.target.value
      }
    }
    if(name === 'email') {
      if(e.target.value !== "") {
        let formatEmail = /^[a-zA-Z0-9@.!#$%&'*+/=?^_`{|}~-]+$/
        if(!formatEmail.test(e.target.value)){
          e.target.value = prevInputState.current
        }
        prevInputState.current = e.target.value
      } else {
        prevInputState.current = ""
      }
      e.target.value = e.target.value.trim()
    }
  }
  return (
    <div className={styles.wrapper}>
      <InputWrapper>
        <InputField
          type={type}
          autoComplete={autoComplete}
          placeholder={placeholderComponent}
          id={id}
          name={name}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          accept={accept}
          label={label}
          size="small"
          rows={rows}
          inputProps={{ maxLength: maxLenght }}
          InputProps={InputProps}
          multiline={multiline}
          error={error && touched}
          hidden={hidden}
          style={style}
          onPaste={handleOnPaste}
          onInput={handleOnInput}
        />
      </InputWrapper>
      {children}
      {!!error && touched && (
        <small className="text-danger fs-6">{error}</small>
      )}
    </div>
  );
};
export default TextField;
