import React from 'react';

const FileBastse64 = (props) => {

  const handleChange = (e) => {
    const files = e.target.files;

    let allFiles = [];
    for (let i = 0; i < files.length; i++) {

      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        allFiles.push(fileInfo);
        if (allFiles.length === files.length) {
          if (props.multiple) props.onDone(allFiles);
          else props.onDone(allFiles[0]);
        }

      }

    }

  }
  return (
    <input
      type="file"
      onChange={handleChange}
      multiple={props.multiple}
      name={props.name}
      id={props.id}
      onBlur={props.onBlur}
      accept="image/png, image/jpeg"
      style={props.style}
    />
  );
}

export default FileBastse64;