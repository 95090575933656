export const firstStepFormValues = {
  name: "",
  categoryId: "",
  codePhone: "",
  phone: "",
  files: {},
  address: "",
  description: "",
  stateRegion: "",
  city: "",
  geo: [],
};
