import styles from "./Button.module.scss";

const Button = ({ type, white, onClick, text, disabled = true, opacity = true }) => {
  return (
    <button
      className={`${styles["wrapper__button"]} ${
        white ? `${styles["wrapper__button__white"]}` : ""
      }`}
      onClick={onClick}
      type={type}
      disabled={!disabled}
      style={{opacity: opacity ? 1 : 0.5}}
    >
      {text}
    </button>
  );
};

export default Button;
