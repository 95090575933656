import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueuemApp } from "./QueuemApp";
import "./index.css";
import "./styles/index.scss";

Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });


const container = document.getElementById("root");
const root = createRoot(container);
root.render(<QueuemApp />);
