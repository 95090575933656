import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { secondStepFormValues } from "../../../helpers/secondStepFormValues";
import { Button, TextField } from "../../";
import styles from "../../step/Steps.module.scss";

const SecondStep = ({ updateForm, previousStep, nextStep }) => {
  const [next, setNext] = useState(true);

  const handleSubmitForm = async (values, setSubmitting) => {
    if (next === true) {
      Object.keys(values).map((value) => updateForm(value, values[value]));
      setSubmitting(false);
      nextStep();
    }
  };

  const previousStep1 = () => {
    setNext(false);
    previousStep();
  };

  const nextStep1 = () => {
    setNext(true);
  };

  return (
    <div className={styles.wrapper__body}>
      <p className={styles.wrapper__body__title}>
        Cuéntanos un poco más, ¿qué servicios ofreces?
      </p>

      <Formik
        initialValues={secondStepFormValues}
        validationSchema={Yup.object().shape({
          name_service: Yup.string()
          .required("Este campo es obligatorio.")
          .matches(/^[a-zA-ZÀ-ÿñÑ]+( [a-zA-ZÀ-ÿñÑ]+)*$/, "El campo solo acepta letras y espacios entre palabras")
          .min(3, "El campo debe tener entre 3 y 50 caracteres.")
          .max(50, "El campo debe tener entre 3 y 50 caracteres."),
          display_service: Yup.string()
            .min(3, "El campo debe tener entre 3 y 50 caracteres.")
            .max(50, "El campo debe tener entre 3 y 50 caracteres.")
            .matches(/^[a-zA-ZÀ-ÿñÑ]+( [a-zA-ZÀ-ÿñÑ]+)*$/, "El campo solo acepta letras y espacios entre palabras")
            .required("Este campo es obligatorio."),
          advice_number: Yup.number()
            .min(0, "Ingrese un numero mayor a 0")
            .max(10, "Solo se pueden tener 10 turnos de preaviso como máximo.")
            .required("Introduce un número entre 0 y 10."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmitForm(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          validating,
          valid,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form
              name="SecondStep"
              method="post"
              onSubmit={handleSubmit}
              className={styles.wrapper__body__form}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name_service}
                    value={values.name_service}
                    touched={touched.name_service}
                    name="name_service"
                    //placeholderComponent="Nombre del servicio"
                    label="Nombre del servicio *"
                    maxLenght={50}
                    noSpecialCharacters
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.display_service}
                    value={values.display_service}
                    touched={touched.display_service}
                    name="display_service"
                    //placeholderComponent="Nombre breve a mostrar"
                    label="Nombre breve a mostrar *"
                    maxLenght={50}
                    noSpecialCharacters
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.advice_number}
                    value={values.advice_number}
                    touched={touched.advice_number}
                    name="advice_number"
                    //placeholderComponent="Turnos de preaviso"
                    label="Turnos de preaviso *"
                    InputProps={{ min: 0, max: 10, maxLenght: 2 }}
                  />
                </div>
                <div className="text-end mt-5">
                  <Button white type="button" text="Volver" onClick={previousStep1} />
                  <Button type="submit" text="Continuar" onClick={nextStep1} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default SecondStep;
