export const secondStepFormValues = {
  name_service: '',
  display_service: '',
  description_service: '',
  advice_number: '',
  location_name: '',
  location_address: '',
  location_phone: '',
  location_city: '',
  location_state_region: '',
  location_postal_code: ''

  // firstname: '',
  // lastname: '',
  // secondlastname: '',
  // email: '',
  // username: '',
  // password: '',
  // confirmpassword: ''

}