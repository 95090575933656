//import API from "./config";
import { firstStepFormValues } from "../helpers/firstStepFormValues";
import axios from "axios";

let url = process.env.REACT_APP_URL_BACKEND;
//let url = `http://localhost:8080`;

const DataSource = {
  register: {
    postRegister: async () => {
      const fullData = dataFormat(firstStepFormValues);

      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      //const { limit, skip } = paging
      //const { sort, order_by } = paging/
      //return await (await API.get(`/imports?limit=${limit}&skip=${skip}&sort=${sort}&order_by=${order_by}`)).data
      // let url = `https://pre-queuem-api.waima.es/api/register?access_token=AwGFWnK7We7HwUaE4zZGnVmKfxM5WT7PHVsylRTcGOKoAHkJ8r3PUKcM3Jlnk0jL`;

      try {
        const { data } = await axios.post(url + `/register`, fullData, config);
        return data;
      } catch (error) {
        console.error(error);
        return error.response.data;
      }
    },
  },
};

const dataFormat = (formValues) => {
  const autoGenPatter = generatePattern(formValues.name_service);
  var arrayCode = formValues.codePhone.split(" ");
  const phoneNumber = "+" + arrayCode[1] + formValues.phone;
  var arrayPostCode = formValues.address.split(",");
  const postCode = parseInt(arrayPostCode[arrayPostCode.length - 2]);
  var arrayType = formValues.files.type.split("/");
  const imgType = arrayType[1];
  var arrayBase = formValues.files.base64.split(",");
  const logoBase64 = arrayBase[1]
  return {
    name: formValues.name,
    status: true,
    description: formValues.description,
    address: formValues.address,
    phone: phoneNumber,
    categoryId: formValues.categoryId,
    background: "https://img.freepik.com/foto-gratis/resumen-superficie-texturas-muro-piedra-hormigon-blanco_74190-8189.jpg?t=st=1655835174~exp=1655835774~hmac=5ecf098f0759fbccffe8299eae2830419cc06eac04b022fba97c5a8c0df2951e&w=1380", //todo
    logo: {
      base64: logoBase64,
      filename: formValues.files.name,
      filesize: formValues.files.file.size,
      filetype: formValues.files.type,
      organizationName: formValues.name,
      type: imgType,
    },
    location: {
      name: formValues.name,
      status: true,
      askingName: false,
      asking_phone: false,
      description: formValues.description,
      address: formValues.address,
      phone: phoneNumber,
      postCode,
      stateRegion: formValues.stateRegion,
      city: formValues.city,
      geo: formValues.geo,
      isFromRegister: true,
      isActiveFromApp: true,
    },
    services: [
      {
        name: formValues.name_service,
        display: formValues.display_service,
        pattern: autoGenPatter,
        advice_number: formValues.advice_number,
        description: formValues.name_service,
        promo: "",
        btn_image: "",
        kiosk: {
          name: `kiosk-${autoGenPatter}`,
          status: true,
          description: "online",
        },
        workstation: {
          name: `puesto-${autoGenPatter}`,
          status: true,
          place: "online",
        },
      },
    ],
    area: {
      name: "online",
      place: "online",
    },
    screen: {
      name: `screen-${autoGenPatter}`,
      status: true,
      rows: 5,
    },
    user: {
      firstname: formValues.firstname,
      lastname: `${formValues.lastname} ${formValues.secondlastname}`,
      phone: phoneNumber,
      username: formValues.username,
      email: formValues.email,
      password: formValues.password,
      role: "ADMIN",
    },
  };
};

const generatePattern = (serviceName) => {
  return `${serviceName.slice(0, 2).toUpperCase()}-${generateRandomNumber()}`;
};

const generateRandomNumber = (min = 1000, max = 9999) => {
  return min + Math.floor(Math.random() * (max - min));
};

export const getCategories = async () => {
  const ref = url + `/api/categories`;
  const { data } = await axios.get(ref);
  return data;
}

export default DataSource;
