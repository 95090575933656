import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { thirdStepFormValues } from "../../../helpers/thirdStepFormValues";
import { Button, TextField } from "../../";
import styles from "../../step/Steps.module.scss";

const ThirdStep = ({ updateForm, previousStep, nextStep }) => {
  const [next, setNext] = useState(true);

  const handleSubmitForm = async (values, setSubmitting) => {
    if (next === true) {
      Object.keys(values).map((value) => updateForm(value, values[value]));
      setSubmitting(false);
      nextStep();
    }
    //
  };

  const previousStep1 = () => {
    setNext(false);
    previousStep();
  };

  const nextStep1 = () => {
    setNext(true);
  };

  return (
    <div className={styles.wrapper__body}>
      <p className={styles.wrapper__body__title}>Datos personales</p>

      <Formik
        initialValues={thirdStepFormValues}
        validationSchema={Yup.object().shape({
          firstname: Yup.string()
            .required("Este campo es obligatorio.")
            .min(2, "El campo es demasiado corto.")
            .max(40, "El campo no debe exceder los 40 caracteres.")
            .matches(/^(?!\s|.*\s$).*$/, "El campo no acepta espacios al inicio o al final.")
            .matches(/^[a-zA-ZÀ-ÿñÑ]+( [a-zA-ZÀ-ÿñÑ]+)*$/, "El campo solo acepta letras y un espacio entre palabras"),
          lastname: Yup.string()
            .required("Este campo es obligatorio.")
            .min(2, "El campo es demasiado corto.")
            .max(40, "El campo no debe exceder los 40 caracteres.")
            .matches(/^(?!\s|.*\s$).*$/, "El campo no acepta espacios al inicio o al final.")
            .matches(/^[a-zA-ZÀ-ÿñÑ]+( [a-zA-ZÀ-ÿñÑ]+)*$/, "El campo solo acepta letras y un espacio entre palabras"),
          secondlastname: Yup.string()
            .min(2, "El campo es demasiado corto.")
            .max(40, "El campo no debe exceder los 40 caracteres.")
            .matches(/^(?!\s|.*\s$).*$/, "El campo no acepta espacios al inicio o al final.")
            .matches(/^[a-zA-ZÀ-ÿñÑ]+( [a-zA-ZÀ-ÿñÑ]+)*$/, "El campo solo acepta letras y un espacio entre palabras"),
          email: Yup.string()
            .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Email no valido.")
            .email("Email no valido")
            .required("Este campo es obligatorio."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmitForm(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          validating,
          valid,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form
              name="ThirdStep"
              method="post"
              onSubmit={handleSubmit}
              className={styles.wrapper__body__form}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstname}
                    value={values.firstname}
                    touched={touched.firstname}
                    name="firstname"
                    //placeholderComponent="Nombre"
                    label="Nombre *"
                    maxLenght={40}
                    noSpecialCharacters
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastname}
                    value={values.lastname}
                    touched={touched.lastname}
                    name="lastname"
                    //placeholderComponent="Primer Apellido"
                    label="Primer apellido *"
                    maxLenght={40}
                    noSpecialCharacters
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.secondlastname}
                    value={values.secondlastname}
                    touched={touched.secondlastname}
                    name="secondlastname"
                    //placeholderComponent="Segundo Apellido"
                    label="Segundo apellido"
                    maxLenght={40}
                    noSpecialCharacters
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    value={values.email}
                    touched={touched.email}
                    name="email"
                    label="Email *"
                  />
                </div>
                <div className="text-end mt-5">
                  <Button white type="button" text="Volver" onClick={previousStep1} />
                  <Button type="submit" text="Continuar" onClick={nextStep1} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default ThirdStep;
