import { borderColor } from "@mui/system";
import Select from "react-select";
import styles from "./SelectComponent.module.scss";

const SelectComponent = ({
  onChange,
  onBlur,
  nameValue,
  nameComponent,
  value,
  error,
  touched,
  placeholderComponent,
  options,
}) => {
  const handleChange = (value) => {
    onChange(`${nameComponent}`, value.value);
  };

  const handleBlur = (value) => {
    onBlur(`${nameComponent}`, true);
  };

  const customStyles = {
    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   width: 200,
    //   height: 32,
    //   backgroundColor: "#fff",
    //   backgroundClip: "padding-box",
    //   border: "1px solid #ced4da",
    // }),
    placeholder: ( styles ) => {
      return {
        ...styles,
        color: '#c2c9d1'
      }
    },
    control: (styles, { isFocused }) => {
      return { 
        ...styles,
        height: 30,
        textAlign: "center",
        border: isFocused?"1px solid #FF944E" :"1px solid #D9D9D9",
        boxShadow: isFocused ? '0 0 0 1px #FF944E':'0',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #FF944E',
          borderColor: '#FF944E'
        },
        ':active': {
          ...styles[':active'],
          boxShadow: '0 0 0 1px #FF944E',
        },
        ':focus': {
          ...styles[':focus'],
          boxShadow: '0 0 0 1px #FF944E',
        }
      }
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        overflow: 'visible !important',
        color: 'black !important',
        width: '370px !important',
        backgroundColor: isSelected ? '#FF944E': undefined,
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected 
            ? '#FF944E': undefined
        },
        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? undefined : '#FFCCAD',
        }
      }
    },
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  return (
    <div>
      <Select
        className={styles.wrapper}
        placeholder={placeholderComponent}
        id={nameComponent}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value.value}
        styles={customStyles}
      />
      {!!error && touched && (
        <small className="text-danger fs-6">{error}</small>
      )}
    </div>
  );
};
export default SelectComponent;
