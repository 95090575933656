import React from "react";
import styles from "./Layout.module.scss";

const Footer = () => {
  return (
    <footer className={styles.wrapper__footer}>
      <ul>
        <li>
          <a
            className={styles.wrapper__footer__minilogotype}
            href="https://waima.es/yopidoturnodesdecasa/"
          >
            <img src="/logoBookTheCitySimple.png" alt="" height="40" />
          </a>
        </li>

        <li>Términos y Condiciones |</li>
        <li>Política de Privacidad |</li>
        <li>Política de Cookies</li>
      </ul>
    </footer>
  );
};

export default Footer;
