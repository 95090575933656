import { useState } from "react";
import StepWizard from "react-step-wizard";
import { firstStepFormValues } from "../../helpers/firstStepFormValues";
import {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
} from "../../components/step/steps/";
import { Nav, Steps } from "../../components/step/";
import styles from "../../components/step/Steps.module.scss";

export const RegisterScreen = () => {
  const stepDetails = [
    { name: "Información comercio", number: 1 },
    { name: "Detalle servicio", number: 2 },
    { name: "Contacto", number: 3 },
    { name: "Registro", number: 4 },
    { name: "Finalizar", number: 5 },
  ];

  const [state, updateState] = useState({ form: firstStepFormValues });

  const updateForm = (key, value) => {
    const { form } = state;

    form[key] = value;
    updateState({
      ...state,
      form,
    });
  };

  const setInstance = (SW) => {
    updateState({
      ...state,
      SW,
    });
  };

  return (
    <div className="screen_container">
      <Steps>
        <StepWizard
          nav={<Nav stepDetails={stepDetails} />}
          instance={setInstance}
          className={styles.wrapper__container}
        >
          <FirstStep updateForm={updateForm} form={state.form} />
          <SecondStep updateForm={updateForm} form={state.form} />
          <ThirdStep updateForm={updateForm} form={state.form} />
          <FourthStep updateForm={updateForm} form={state.form} />
          <FifthStep />
        </StepWizard>
      </Steps>
    </div>
  );
};
