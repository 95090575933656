import React, { useEffect, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { fourthStepFormValues } from "../../../helpers/fourthStepFormValues";
import DataSource from "../../../api/index";
import { Button, TextField, PasswordIcon } from "../../";
import styles from "../../step/Steps.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox, FormControlLabel, Grid, Link } from "@mui/material";
import { ModalPoliticas } from "../../modalPoliticas/ModalPoliticas";

const FourthStep = ({ updateForm, previousStep, nextStep }) => {
  const [next, setNext] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const captcha = useRef(null);
  const [validUser, setValidUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      let timer = setTimeout(() => {
        setErrorMessage(null);
      }, [4000]);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  let onSuccess = false;

  const register = async () => {
    const register = await DataSource.register.postRegister({}).catch();
    let typeError = register.toString().split(" ")[0].toLowerCase();
    if (typeError === "organization") {
      setErrorMessage("Organización ya existe.");
    } else if (typeError === "username") {
      setErrorMessage("Username ya existe.");
    } else if (typeError === "email") {
      setErrorMessage("Ya se registro comercio con este email.");
    } else if (register === "Username already exist.") {
      setErrorMessage("Usuario ya existe.");
    } else if (register.result !== undefined) {
      setErrorMessage(register.result.detail);
    }
    if (register.confirmation === "CREATED") {
      onSuccess = true;
    }
  };

  const handleSubmitForm = async (values, setSubmitting) => {
    if (next === true) {
      Object.keys(values).map((value) => updateForm(value, values[value]));
      setSubmitting(false);
      await register();
      if (onSuccess === true) {
        nextStep();
      }
    }
  };

  const previousStep1 = () => {
    setNext(false);
    previousStep();
  };

  const nextStep1 = () => {
    setNext(true);
  };

  const onChange= () => {
    if (captcha.current.getValue()) {
      setValidUser(true)
    } else {
      setValidUser(false)
    }
  }

  useEffect(() => {
    console.log(validUser)
  }, [])
  

  return (
    <div className={styles.wrapper__body}>
      <p className={styles.wrapper__body__title}>Registro de usuario</p>

      <Formik
        initialValues={fourthStepFormValues}
        validationSchema={Yup.object().shape({
          checkpoliticas: Yup.boolean().isTrue("Debe aceptar la política de privacidad."),
          checkComunicaciones: Yup.boolean().isTrue("Debe aceptar el envió de comunicaciones electrónicas."),
          username: Yup.string()
            .required("Este campo es obligatorio.")
            .min(2, "El campo es demasiado corto, debe tener como mínimo 2 caracteres.")
            .max(25, "El campo no debe exceder los 25 caracteres.")
            .matches(/^(?![-])(?!.*[-]{2})[a-zA-Z0-9._-]+(?<![-])$/, "El campo solo acepta caracteres alfanuméricos y un guión"),
          password: Yup.string()
            .matches(
              new RegExp(/(?=.*[A-Z])/),
              "Debe contener al menos una mayúscula, un número y un carácter especial @#$%^&*"
            )
            .matches(
              new RegExp(/(?=.*[0-9])/),
              "Debe contener al menos una mayúscula, un número y un carácter especial @#$%^&*"
            )
            .matches(
              new RegExp(/(?=.*[!@#$%^&*])/),
              "Debe contener al menos una mayúscula, un número y un carácter especial @#$%^&*"
            )
            .min(8, "Debe contener al menos 8 caracteres")
            .trim("Este campo no puede contener espacios")
            .strict()
            .required("La contraseña es obligatoria"),
          confirmpassword: Yup.string()
            .oneOf(
              [Yup.ref("password"), null],
              "Las contraseñas deben coincidir"
            )
            .required("Este campo es obligatorio."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmitForm(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          validating,
          valid,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form
              name="FourthStep"
              method="post"
              onSubmit={handleSubmit}
              className={styles.wrapper__body__form}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.username}
                    value={values.username}
                    touched={touched.username}
                    name="username"
                    label="Nombre de usuario *"
                    autoComplete="off"
                    maxLenght={25}
                    userName
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    value={values.password}
                    touched={touched.password}
                    name="password"
                    label="Contraseña *"
                    autoComplete="off"
                  >
                    {/* <button
                      className={styles.password}
                      onClick={(e) => toggleShown()}
                    >
                      {shown && <FaEye />}
                      {!shown && <FaEyeSlash />}
                    </button> */}
                    <PasswordIcon
                      state={showPassword}
                      action={setShowPassword}
                    />
                  </TextField>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type={showConfirmationPassword ? "text" : "password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.confirmpassword}
                    value={values.confirmpassword}
                    touched={touched.confirmpassword}
                    name="confirmpassword"
                    label="Confirmar contraseña *"
                    autoComplete="off"
                  >
                    <PasswordIcon
                      state={showConfirmationPassword}
                      action={setShowConfirmationPassword}
                    />
                  </TextField>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                    <ReCAPTCHA
                      ref={captcha}
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={onChange}
                    />
                    {errorMessage && (
                      <div className="w-full text-center text-lg-start fw-normal mt-4 text-danger">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-xs-12 mt-3" >
                    <div className={styles.checkbox__box}>
                      <div className={styles.label__checkbox}>
                        <input
                          type="checkbox"
                          name="checkpoliticas"
                          onChange={handleChange}
                          value={values.checkpoliticas} />
                        <label> Acepto la <Link onClick={() => setOpenModal(true)}>política de privacidad.</Link> *</label>
                      </div>
                      <ModalPoliticas openModal={openModal} handleClose={() => setOpenModal(false)} />
                      <div>
                        {errors.checkpoliticas && touched.checkpoliticas && (
                          <small className="text-danger fs-6">{errors.checkpoliticas}</small>
                        )}
                      </div>
                    </div>
                    <div className={styles.checkbox__box}>
                      <div className={styles.label__checkbox}>
                        <input
                          type="checkbox"
                          size="large"
                          name="checkComunicaciones"
                          onChange={handleChange}
                          value={values.checkComunicaciones} />
                        <label>Acepto el envío de comunicaciones electrónicas. *</label>
                      </div>
                      {errors.checkComunicaciones && touched.checkComunicaciones && (
                        <small className="text-danger fs-6">{errors.checkComunicaciones}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-end mt-5">
                  <Button
                    white
                    type="button"
                    text="Volver"
                    onClick={previousStep1}
                  />
                    <Button
                      disabled={validUser}
                      opacity={validUser}
                      type="submit"
                      text="Continuar"
                      onClick={nextStep1}
                    />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default FourthStep;
