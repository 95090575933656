import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import styles from "./ModalPoliticas.module.scss"

export const ModalPoliticas = ({ openModal, handleClose }) => {
  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box className={styles.modal__style}>
        <div className={styles.container__politicas}>
          <div>
            <h1 className={styles.title__h1}>Política de privacidad</h1>
            <br />
            <br />
            <h2 className={styles.title__h2}>1. Datos del Responsable del Tratamiento</h2>
            <div>
              <p>
                - Denominación Social: Waima Technologies., en adelante, «La empresa».
              </p>
            </div>

            <div>
              <p>
                - Domicilio Social: Calle Capellanes, 8. 28902. Getafe Madrid.
              </p>
            </div>
            <div>
              <p>
                - NIF: B88225271.
              </p>
            </div>
            <div>
              <p>
                - Teléfono: 917647982.
              </p>
            </div>
            <div>
              <p>- Email: contacta@waima.es​</p>
            </div>
            <br />
            <br />
            <h3 className={styles.title__h3}>1.1 Normativa aplicable</h3>
            <p>Nuestra Política de Privacidad se ha diseñado de acuerdo con el Reglamento General de Protección de Datos de la UE 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos), y la Ley Orgánica 3/2018 del 5 de diciembre, de Protección de Datos de Carácter Personal y Garantía de los Derechos Digitales. Al facilitarnos sus datos, Usted declara haber leído y conocer la presente Política de Privacidad, prestando su consentimiento inequívoco y expreso al tratamiento de sus datos personales de acuerdo a las finalidades y términos aquí expresados. La Empresa podrá modificar la presente Política de Privacidad para adaptarla a las novedades legislativas, jurisprudenciales o de interpretación de la Agencia Española de Protección de Datos. Estas condiciones de privacidad podrán ser complementadas por el Aviso Legal, Política de Cookies y las Condiciones Generales que, en su caso, se recojan para determinados productos o servicios, si dicho acceso supone alguna especialidad en materia de protección de datos de carácter personal.</p>
            <br />
            <br />
            <h2 className={styles.title__h2}>2. Finalidad del Tratamiento de los Datos Personales</h2>
            <p>El tratamiento que realizamos de sus datos personales responde a las siguientes finalidades:</p>
            <ul>
              <li><p>Identificar a los usuarios de manera única.</p></li>
              <li><p>Activación de usuario.</p> </li>
              <li><p>Posibilitar el cambio de contraseñas.</p></li>
            </ul>
            <br />
            <h3 className={styles.title__h3}>2.1. Plazo de Conservación de sus datos</h3>
            <p>Conservaremos sus datos personales desde que nos dé su consentimiento hasta que lo revoque o bien solicite la limitación del tratamiento. En tales casos, mantendremos sus datos de manera bloqueada durante los plazos legalmente exigidos</p>
            <br />
            <br />
            <h2 className={styles.title__h2}>3. Legitimación y Datos Recabados</h2>
            <p>La legitimación para el tratamiento de sus datos es el consentimiento expreso otorgado mediante un acto positivo y afirmativo (rellenar el formulario correspondiente y marcar la casilla de aceptación de esta política) en el momento de facilitarnos sus datos personales.</p>
            <br />
            <h3 className={styles.title__h3}>3.1 Consentimiento para tratar sus datos</h3>
            <p>Al rellenar los formularios, marcar la casilla “Acepto la Política de Privacidad” y hacer clic para enviar los datos, o al remitir correos electrónicos a la Empresa a través de las cuentas habilitadas al efecto, el Usuario manifiesta haber leído y aceptado expresamente la presente política de privacidad, y otorga su consentimiento inequívoco y expreso al tratamiento de sus datos personales conforme a las finalidades indicadas.</p>
            <br />
            <h3 className={styles.title__h3}>3.2 Categorías de datos</h3>
            <p>La aplicación guarda los siguientes datos personales: Ubicación, nombre, correo electrónico y teléfono.</p>
            <br />
            <br />
            <h2 className={styles.title__h2}>4. Medidas de Seguridad</h2>
            <p>Dentro de nuestro compromiso por garantizar la seguridad y confidencialidad de sus datos de carácter personal, le informamos que se han adoptado las medidas de índole técnica y organizativas necesarias para garantizar la seguridad de los datos de carácter personal y evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que estén expuestos, según el Art. 32 del RGPD EU 679/2016.</p>
            <br />
            <br />
            <h2 className={styles.title__h2}>5. Cesión de Datos</h2>
            <p>No se prevén cesiones de datos ni transferencias internacionales de sus datos, a excepción de las autorizadas por la legislación fiscal, mercantil y de telecomunicaciones así como en aquellos casos en los que una autoridad judicial nos lo requiera.</p>
            <br />
            <br />
            <h2 className={styles.title__h2}>6. Derechos del Usuario</h2>
            <p>Cualquier interesado tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le conciernen, o no. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. Por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. El Responsable del fichero dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. De acuerdo con la legislación vigente tiene los siguientes derechos: derecho a solicitar el acceso a sus datos personales, derecho a solicitar su rectificación o supresión, derecho a solicitar la limitación de su tratamiento, derecho a oponerse al tratamiento, derecho a la portabilidad de los datos y así mismo, a revocar el consentimiento otorgado. Asimismo, tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos.</p>
            <br />
            <h3 className={styles.title__h3}>6.1. ¿Cómo ejercitar mis derechos?</h3>
            <p>Para ejercer sus derechos, debe dirigirse al responsable, solicitando el correspondiente formulario para el ejercicio del derecho elegido. Opcionalmente, puede acudir a la Autoridad de Control competente para obtener información adicional acerca de sus derechos. Los datos de contacto para el ejercicio de sus derechos son el teléfono 917647982 y el correo electrónico: contacta@waima.es​. Recuerde acompañar una copia de un documento que nos permita identificarle.</p>
            <br />
            <br />
            <h2 className={styles.title__h2}>7. Consentimiento para envío de Comunicaciones Electrónicas</h2>
            <p>Así mismo, y de acuerdo con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, completando el formulario de recogida de datos y marcando la correspondiente casilla “Acepto el envío de comunicaciones electrónicas”, está otorgando el consentimiento expreso para enviarle a su dirección de correo electrónico, teléfono, fax u otro medio electrónico en envío de información acerca de la Empresa.</p>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
