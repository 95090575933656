import { Box, TextField, styled } from '@mui/material'
import '../../styles/variables.scss';

export const InputWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  fontFamily: "RobotoSlab400",
  color: '#c2c9d1',
})

export const InputField = styled(TextField)(({ value }) => ({
  width: '100%',
  '& label.Mui-focused': {
    color: '#FF944E',
  },
  '& label': {
    color: value ? '#FF944E' : 'var(--textPrimary)',
    fontSize: '14px',
    fontFamily: 'RobotoSlab400',
  },
  '& .MuiOutlinedInput-root': {
    'input': {
      color: 'var(--textSecondary)',
    },
    'textarea': {
      color: 'var(--textSecondary)',
    },
    fontSize: '14px',
    borderRadius: '6px',
    fontFamily: 'RobotoSlab400',
    '& fieldset': {
      outline: 'none',
      border: value ? '1px solid #FF944E' : '1px solid #D9D9D9',
      opacity: value ? 0.5 : 1,
      boxShadow: value ? '0px 4px 8px rgba(0, 0, 0, 0.16)' : 'none',
      color: 'var(--textPrimary)',
    },
    '&:hover fieldset': {
      outline: 'none',
      border: value ? '1px solid #FF944E' : '1px solid #D9D9D9',
      opacity: value ? 0.5 : 1,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16);',
      color: 'var(--textPrimary)',
    },
    '&.Mui-focused fieldset': {
      outline: 'none',
      border: '1px solid #FF944E',
      opacity: 0.5,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16);',
      color: 'var(--textPrimary)',
    },
  },
}))

export const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 12px;
  transform: translateY(-50%);
  margin: auto;
  transition: all 0.3s ease;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`