import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";


import * as Yup from "yup";
import { getCategories } from "../../../api/index";
import { firstStepFormValues } from "../../../helpers/firstStepFormValues";
import { phoneCode } from "../../../data/phoneCode"

import { SelectComponent, Button, TextField, Geo } from "../../";
import styles from "../../step/Steps.module.scss";
import FileBastse64 from "../../InputBase65";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const FirstStep = ({ updateForm, nextStep }) => {
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [result, setResult] = useState({
    address: "",
    city: "",
    stateRegion: "",
    geo: [],
    valid: false,
    message: "Dirección no encontrada"
  });

  const [categories, setCategories] = useState([{
    name: 'category.name',
    label: 'category.name',
    value: 'category.id',
  }]);

  const handleCallback = (value) => {
    setResult(value);
    firstStepFormValues.address = result;
  };
  const handleSubmitForm = async (values, setSubmitting) => {
    Object.keys(values).map((value) => updateForm(value, values[value]));
    setSubmitting(false);
    firstStepFormValues.address = result.address;
    firstStepFormValues.city = result.city;
    firstStepFormValues.stateRegion = result.stateRegion;
    firstStepFormValues.geo = result.geo;
    firstStepFormValues.valid = result.valid;
    firstStepFormValues.message = result.message;
    nextStep();
  };

  const validationSchema = Yup.object({
    files: Yup.object().shape({
      file: Yup.mixed().required("Imagen requerida."),
    }),
    name: Yup.string().required("Nombre requerido")
      .min(2, "El campo es demasiado corto.")
      .max(100, "El campo no debe exceder los 100 caracteres.")
      .matches(/^(?!\s|.*\s$).*$/, "El campo no acepta espacios al inicio o al final.")
      .matches(/^[a-zA-Z0-9À-ÿ\u00f1\u00d1]+(\s[a-zA-Z0-9À-ÿ\u00f1\u00d1]+)*$/, "El campo solo acepta caracteres alfanuméricos."),
    categoryId: Yup.string().required("Categoría requerida"),
    codePhone: Yup.string().required("Código de país requerido"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "El campo solo admite números.")
      .min(8, "El campo es demasiado corto.")
      .max(10, "El campo no debe exceder los 10 caracteres.")
      .required("Número de teléfono requerido"),
    description: Yup.string().required("Descripción requerida")
      .min(2, "El campo es demasiado corto.")
      .max(250, "El campo no debe exceder los 250 caracteres.")
      .matches(/^[A-Za-z0-9À-ÿ\u00f1\u00d1\s:,.-]*[A-Za-z0-9][A-Za-z0-9À-ÿ\u00f1\u00d1\s:,.-]*$/, "El campo no acepta símbolos."),
  });
  const codePhonePackage = phoneCode.map((code) => ({
    name: 'phone',
    label: `${code.nombre} (${code.phone_code})`,
    value: code.iso3 + " " + code.phone_code,
  }))

  useEffect(() => {
    const getAllCategories = async () => {
      const categories = await getCategories();
      categories.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });
      setCategories(
        categories.map((category) => ({
          name: category.name,
          label: category.name,
          value: category.id,
        }))
      );
    }
    getAllCategories();

  }, [])

  return (
    <div className={styles.wrapper__body}>
      <p className={styles.wrapper__body__title}>
        ¡Bienvenido, registre su comercio aquí!
      </p>
      <Formik
        initialValues={firstStepFormValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (result.valid) {
              await handleSubmitForm(values, setSubmitting);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          validating,
          valid,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form
              name="FirstStep"
              method="post"
              onSubmit={handleSubmit}
              className={styles.wrapper__body__form}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name}
                    value={values.name}
                    touched={touched.name}
                    name="name"
                    label="Nombre del comercio *"
                    maxLenght={100}
                    noSpecialCharacters
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 mt-3" style={{ zIndex: 20 }}>
                  <SelectComponent
                    options={categories}
                    value={values.categoryId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.categoryId}
                    touched={touched.categoryId}
                    nameComponent="categoryId"
                    placeholderComponent="Categoría *"
                  />
                </div>
                <div className="col-lg-3 col-md-3 col-xs-12 mt-3" style={{ zIndex: 10 }}>
                  <SelectComponent
                    options={codePhonePackage}
                    value={values.codePhone}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.codePhone}
                    touched={touched.codePhone}
                    nameComponent="codePhone"
                    placeholderComponent="Código País *"
                  />
                </div>

                <div className="col-lg-3 col-md-3 col-xs-12 mt-3">
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone}
                    value={values.phone}
                    touched={touched.phone}
                    name="phone"
                    label="Teléfono *"
                    maxLenght={10}
                    onlyNumbers
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <label htmlFor="fileInput" className={styles.imagePickerLabel} style={{ cursor: 'pointer', position: "relative" }}>
                    <input type="text" placeholder="Logotipo *" disabled value={values.files.name || ''} className={styles.inputAsText} style={{ cursor: 'pointer' }} />
                  </label>
                  <FileBastse64 id="fileInput" name="files" onBlur={handleBlur} onDone={(file) => {
                    const str = file.size;
                    const num = parseInt(str.replace(/\D/g,''));
                    const png = file.type === 'image/png';
                    const jpeg = file.type === 'image/jpeg';
                    const jpg = file.type === 'image/jpg';
                    
                    if (!(jpg || jpeg || png)) {
                      setErrorFile(true);
                      return
                    }
                    if (num > 5000) {
                      setErrorSize(true);
                      return
                    }
                    setErrorSize(false);
                    setErrorFile(false);
                    setFieldValue('files', file);
                  }} style={{ display: 'none' }} accept="image/png, image/jpeg" />
                  <label htmlFor="fileInput" className={styles.labelAsButton} style={{ cursor: 'pointer' }}>
                    Seleccionar
                  </label>
                  {errors.files?.file ? (<small className="text-danger fs-6">{errors.files?.file}</small>) : null}
                  {errorFile && (<small className="text-danger fs-6">El formato de archivo no es válido.</small>)}
                  {errorSize && (<small className="text-danger fs-6">La imagen no debe ser mayor a 5mb.</small>)}
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <Geo
                    getResult={handleCallback}
                    result={result}
                    setResult={setResult}
                    styles={styles}
                  />
                  {result.valid ? null : (<div className={styles.error}>{result.message}</div>)}
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
                  <TextField
                    label="Descripción del comercio *"
                    autoComplete="off"
                    multiline
                    rows={8}
                    error={errors.description}
                    value={values.description}
                    touched={touched.description}
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLenght={250}
                    noSpecialCharacters
                  />
                  <div className="text-end mt-5">
                    {/* <Button white type="button" text="Salir" /> */}
                    <Button type="submit" text="Continuar" />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div >
  );
};
export default FirstStep;
