const env_variable = {
  dev: {
    redirect_url: 'http://localhost:3000'
  },
  pre: {
    redirect_url: process.env.REACT_APP_REDIRECT_FINISH_STEP_PRE
  },
  pro: {
    redirect_url: process.env.REACT_APP_REDIRECT_FINISH_STEP_PRO
  }
}

export const envs = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "DEV":
      return env_variable.dev;
    case "dev":
      return env_variable.dev;
    case "PRE":
      return env_variable.pre;
    case "pre":
      return env_variable.pre;
    case "PRO":
      return env_variable.production;
    case "pro":
      return env_variable.production;
    default:
      return env_variable.pre;
  }
}
