import { RegisterScreen } from "./pages/register/RegisterScreen";
import Layout from "./components/layout/Layout";
import * as React from "react";

export const QueuemApp = () => {
  return (
    <Layout>
      <RegisterScreen />
    </Layout>
  );
};
