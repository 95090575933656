import React from "react";
import styles from "./Layout.module.scss";

const Header = () => {
  return (
    <nav className={styles.wrapper__header}>
      <a
        className={styles.wrapper__header__logotype}
        href="https://waima.es/yopidoturnodesdecasa/"
      >
        <img src="/logoBookTheCity.png" alt="" width="130" height="50" />
      </a>
    </nav>
  );
};

export default Header;
