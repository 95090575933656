// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalPoliticas_modal__style__19BiW {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background-color: white;
  padding: 2em;
  border: 2px solid black;
  border-radius: 20px;
}
.ModalPoliticas_modal__style__19BiW p {
  font-size: large;
  text-align: justify;
}
.ModalPoliticas_modal__style__19BiW ul {
  list-style: circle;
  padding-left: 40px;
}

.ModalPoliticas_modal__style__19BiW::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  border-radius: 20px !important;
}

.ModalPoliticas_modal__style__19BiW::-webkit-scrollbar:vertical {
  display: block;
}

.ModalPoliticas_modal__style__19BiW::-webkit-scrollbar-track {
  border-radius: 20px !important;
  height: 80% !important;
}

.ModalPoliticas_container__politicas__yZvS6 {
  height: 100%;
  padding-right: 50px;
  overflow: hidden;
  overflow: scroll;
}

.ModalPoliticas_title__h2__9IIfa {
  font-size: 3em;
  font-weight: bold;
}

.ModalPoliticas_title__h3__9OAb2 {
  font-size: 2em;
  font-weight: bold;
}

.ModalPoliticas_title__h1__YqKLM {
  font-size: 4em;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/modalPoliticas/ModalPoliticas.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAKE;EACE,gBAAA;EACA,mBAAA;AAHJ;AAME;EACE,kBAAA;EACA,kBAAA;AAJJ;;AASA;EACE,sBAAA;EACA,uBAAA;EACA,8BAAA;AANF;;AASA;EACE,cAAA;AANF;;AASA;EACE,8BAAA;EACA,sBAAA;AANF;;AASA;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AANF;;AASA;EACE,cAAA;EACA,iBAAA;AANF;;AASA;EACE,cAAA;EACA,iBAAA;AANF;;AASA;EACE,cAAA;EACA,iBAAA;AANF","sourcesContent":["@import \"./../../styles/variables.scss\";\n\n.modal__style {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 70%;\n  height: 70%;\n  background-color: white;\n  padding: 2em;\n  border: 2px solid black;\n  border-radius: 20px;\n\n\n\n  p {\n    font-size: large;\n    text-align: justify;\n  }\n\n  ul {\n    list-style: circle;\n    padding-left: 40px;\n  }\n\n}\n\n.modal__style::-webkit-scrollbar {\n  width: 10px !important;\n  height: 10px !important;\n  border-radius: 20px !important;\n}\n\n.modal__style::-webkit-scrollbar:vertical {\n  display: block;\n}\n\n.modal__style::-webkit-scrollbar-track {\n  border-radius: 20px !important;\n  height: 80% !important;\n}\n\n.container__politicas {\n  height: 100%;\n  padding-right: 50px;\n  overflow: hidden;\n  overflow: scroll;\n}\n\n.title__h2 {\n  font-size: 3em;\n  font-weight: bold;\n}\n\n.title__h3 {\n  font-size: 2em;\n  font-weight: bold;\n}\n\n.title__h1 {\n  font-size: 4em;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__style": `ModalPoliticas_modal__style__19BiW`,
	"container__politicas": `ModalPoliticas_container__politicas__yZvS6`,
	"title__h2": `ModalPoliticas_title__h2__9IIfa`,
	"title__h3": `ModalPoliticas_title__h3__9OAb2`,
	"title__h1": `ModalPoliticas_title__h1__YqKLM`
};
export default ___CSS_LOADER_EXPORT___;
