import React from "react";
import styles from "../../step/Steps.module.scss";
import ButtonStyles from "../../button/Button.module.scss";
import { envs } from "../../../helpers/envHelper.js"

const FifthStep = () => {

    function sendHomeLink() {
        var newurl = envs().redirect_url;
        window.location.replace(newurl);
    }

    return (
        <div className={styles.wrapper__body}>
            <div className={styles.finishBody}>
                <h1 className={styles.textTitle}>Registro exitoso</h1>
                <div className={styles.labelContainer}>
                    <p className={styles.resultText}>Gracias por registrar tu comercio, verifica tu correo electrónico para activar tu cuenta.</p>
                </div>
                <button onClick={sendHomeLink} type="button" className={ButtonStyles["wrapper__button"]}>Finalizar</button>
            </div>
        </div>
    );
};
export default FifthStep;
