// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectComponent_wrapper__BMkVK div {
  font-family: "RobotoSlab400";
  font-size: 14px;
  line-height: 1.6rem;
  align-content: center;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 6px;
}

#SelectComponent_categoryName__KYE6h div {
  color: var(--textPrimary);
}`, "",{"version":3,"sources":["webpack://./src/components/select/SelectComponent.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAGE;EAEE,4BCUI;EDTJ,eCeY;EDdZ,mBCwBc;EDvBd,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAHJ;;AAQA;EACE,yBAAA;AALF","sourcesContent":["@import \"./../../styles/variables.scss\";\n\n.wrapper {\n  div {\n    //color: var(--textPrimary);\n    font-family: $ff400;\n    font-size: $fontSizeSmall;\n    line-height: $lineHeightSmall;\n    align-content: center;\n    margin-left: 0px;\n    margin-right: 0px;\n    border-radius: 6px;\n  }\n \n}\n\n#categoryName div{\n  color: var(--textPrimary);\n}\n","// Breakpoints\n$bpExtraSmall: 480px;\n$bpSmall: 768px;\n$bpMedium: 1024px;\n$bpLarge: 1280px;\n$bpExtraLarge: 1366px;\n$bpHuge: 1920px;\n$mainWidth: 883px;\n\n// Font\n$ff900: 'RobotoSlab900';\n$ff800: 'RobotoSlab800';\n$ff700: 'RobotoSlab700';\n$ff600: 'RobotoSlab600';\n$ff500: 'RobotoSlab500';\n$ff400: 'RobotoSlab400';\n$ff300: 'RobotoSlab300';\n$ff200: 'RobotoSlab200';\n$ff100: 'RobotoSlab100';\n\n$fontSizeExtraSmall: 1rem;\n$fontSizeSmall: 14px;\n$fontSizeExtraBase: 1.4rem;\n$fontSizeBase: 1.6rem;\n$fontSizeMedium: 1.8rem;\n$fontSizeLarge: 2rem;\n$fontSizeExtraLarge: 2.4rem;\n$fontSizeHuge: 3.2rem;\n$fontSizeMassive: 4.8rem;\n\n$lineHeightExtraSmall: 1.2rem;\n$lineHeightSmall: 1.6rem;\n$lineHeightExtraBase: 2rem;\n$lineHeightBase: 2.2rem;\n$lineHeightExtraMedium: 2.4rem;\n$lineHeightMedium: 2.6rem;\n$lineHeightLarge: 2.8rem;\n$lineHeightExtraLarge: 3.6rem;\n$lineHeightHuge: 4.8rem;\n$lineHeightMassive: 6.2rem;\n\n// z-index\n$z-IndexBelow: -1;\n$z-IndexAbove: 1;\n$z-IndexModal1: 10;\n$z-IndexModal2: 25;\n$z-IndexModal3: 50;\n\n// Transitions\n$transitionNormal: 0.5s;\n\n// Header\n$headerHeight: 7.2rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SelectComponent_wrapper__BMkVK`,
	"categoryName": `SelectComponent_categoryName__KYE6h`
};
export default ___CSS_LOADER_EXPORT___;
