import { FaEyeSlash, FaEye } from "react-icons/fa";
import styles from "./PasswordIcon.module.scss";

const PasswordIcon = ({ state, action }) => {
  const toggleShown = () => {
    action((prevShown) => !prevShown);
  };

  return (
    <button className={styles.wrapper} type="button" onClick={(e) => toggleShown()}>
      {state && <FaEye />}
      {!state && <FaEyeSlash />}
    </button>
  );
};
export default PasswordIcon;
