import React, { useState, useRef, useEffect, useMemo } from "react";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import { MapContainer, Marker } from "react-leaflet";
import { useMap } from 'react-leaflet/hooks';
import { TileLayer } from 'react-leaflet';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';
import EsriLeafletGeoSearch from "react-esri-leaflet/plugins/EsriLeafletGeoSearch";
import reverseGeoCoding from "./reverseGeocoding";
import TextField from '../textField/TextField';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const ESRI_GEOCODER = "AAPK51717e21b719479c82aff603d7992ad1wHaVB9G1dowK1E-Oy7W1BqyeXigxUiKqhw-ApwK7HM7GQGkPlBLghz8L3wWpfOKF"

const Geo = ({ getResult, result, setResult, styles }) => {

  const [center, setCenter] = useState({
    lat: 40.4165,
    lng: -3.70256
  });
  const [marker, setMarker] = useState({
    lat: 40.4165,
    lng: -3.70256
  });

  const newCenter = { ...center };
  const newMarker = { ...marker };

  const ZOOM_LEVEL = 16;
  const mapRef = useRef();
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }

  const onSuccess = (location) => {
    newCenter.lat = location.coords.latitude;
    newCenter.lng = location.coords.longitude;
    setCenter(newCenter);

    newMarker.lat = location.coords.latitude;
    newMarker.lng = location.coords.longitude;
    setMarker(newMarker);
    addressData({ lat: location.coords.latitude, lng: location.coords.longitude });
  }

  const onError = () => {
    newCenter.lat = 40.4165;
    newCenter.lng = -3.70256;
    setCenter(newCenter);
  }

  useEffect(() => {
    if (!("Geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation is not supported by this browser.",
      })
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
  }, []);

  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setCenter(marker.getLatLng())
          addressData(marker.getLatLng())
        }
      },
    }),
    [],
  )

  const addressData = async (center) => {
    const data = await reverseGeoCoding(center);
    setResult({
      address: data.address.LongLabel,
      city: data.address.City === "" ? data.address.LongLabel : data.address.City,
      stateRegion: data.address.CntryName,
      geo: center,
      valid: true,
      message: "Dirección encontrada"
    });
  }

  function GetUserInitialPosition() {
    const map = useMap();
    if (center) {
      map.panTo(center);
    }
    return null
  }

  useEffect(() => {
    getResult(result);
  }, [center])


  const results = (r) => {
    const { LongLabel, City, CntryName, DisplayX, DisplayY } = r.results[0].properties
    setResult({
      address: LongLabel,
      city: City === "" ? LongLabel : City,
      stateRegion: CntryName,
      geo: {lat: DisplayY, lng: DisplayX},
      valid: true,
      message: "Dirección encontrada"
    });
    setMarker({
      lat: DisplayY,
      lng: DisplayX
    });
    setCenter({
      lat: DisplayY,
      lng: DisplayX
    });
  }
  const handlerOnChange = (e) => {
    console.log(e.target.value)
  }
  return (
    <div>
      <TextField className={styles.inputText} label="Dirección" onChange={handlerOnChange} InputProps={{readOnly: false}} value={result.address} />
      <h1 className={styles.labelText}>Elija la ubicación del comercio *</h1>
      <div style={{ height: "300px" }}>
        <MapContainer ref={mapRef} style={{ height: "100%", zIndex: 0 }} zoom={ZOOM_LEVEL} center={center} scrollWheelZoom={true}>
          <VectorBasemapLayer name="ArcGIS:Navigation" token={ESRI_GEOCODER} />
          {/*<TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />*/}
          <EsriLeafletGeoSearch
            providers={{ arcgisOnlineProvider: { token: ESRI_GEOCODER, label: "ArcGIS Online Results", maxResults: 10 } }}
            useMapBounds={false}
            position="topright"
            onResults={(results) => { console.log('results', results) }}
            eventHandlers={{ results: (r) => results(r) }}
          />
          <Marker
            ref={markerRef}
            eventHandlers={eventHandlers}
            draggable={true}
            position={marker}
          >
          </Marker>
          <GetUserInitialPosition />
        </MapContainer>
      </div>
    </div>
  );
};

export default Geo;
