// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordIcon_wrapper__fga59 {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px -12px 0px 0px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  border-radius: 50%;
  overflow: visible;
  color: var(--strokeInputDefault);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 10px;
  position: relative;
  float: right;
  top: -35px;
  left: -20px;
}`, "",{"version":3,"sources":["webpack://./src/components/passwordIcon/PasswordIcon.module.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,mBAAA;EACA,sBAAA;EACA,wCAAA;EACA,6BAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gCAAA;EACA,mEAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;AADF","sourcesContent":["@import \"./../../styles/variables.scss\";\n\n.wrapper {\n  display: inline-flex;\n  align-items: center;\n  box-sizing: border-box;\n  -webkit-tap-highlight-color: transparent;\n  background-color: transparent;\n  outline: 0px;\n  border: 0px;\n  margin: 0px -12px 0px 0px;\n  cursor: pointer;\n  -webkit-user-select: none;\n  user-select: none;\n  vertical-align: middle;\n  appearance: none;\n  text-decoration: none;\n  text-align: center;\n  flex: 0 0 auto;\n  font-size: 1.5rem;\n  border-radius: 50%;\n  overflow: visible;\n  color: var(--strokeInputDefault);\n  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  padding: 10px;\n  position: relative;\n  float: right;\n  top: -35px;\n  left: -20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PasswordIcon_wrapper__fga59`
};
export default ___CSS_LOADER_EXPORT___;
